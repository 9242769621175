/* Homepage */
import $ from 'jquery';

export let inactivity = {
    seconds: 120,
    timeout: null
}

$(document).ready(function () {
    // Check if needs the screensaver
    if(location.search.indexOf('screensaver=on') > 0) {
        startScreensaver();
    }
    else {
        stopScreensaver();
        restartInactivityTimeout();
    }
});

// Inactivity
function restartInactivityTimeout() {
    clearTimeout(inactivity.timeout);

    inactivity.timeout = setTimeout(inactivityTimeoutOver, inactivity.seconds * 1000);
}

// Stop screensaver
$('section.homepage .screensaver').on('click', function() {
    stopScreensaver();
});

// Restart inactivity timeout
$('body').on('click', function() {
    restartInactivityTimeout();
});

function inactivityTimeoutOver() {
    // Move back to homepage and start the screensaver
    window.location.href = "./index.html?screensaver=on";
}

function startScreensaver() {
    $('section.homepage').addClass('screensaver-on').removeClass('screensaver-off');

    // Start the video screensaver
    $('section.homepage .background video').get(0).play();
}

function stopScreensaver() {
    $('section.homepage').addClass('screensaver-off').removeClass('screensaver-on');

    // Stop the video screensaver
    if($('section.homepage .background video').length > 0) {
        $('section.homepage .background video').get(0).pause();
    }
}

/* Homepage (end) */
