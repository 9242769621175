import $ from 'jquery';
import YouTubePlayerPlus from 'youtube-player-plus';

$(document).ready(function () {
  $('.video-poster').each(function (index, poster) {
    const $button = $(poster).find('.video-play-button').length ? $(poster).find('.video-play-button') : $(poster).next('.video-play-button');
    const $modal = $('#' + $button.attr('aria-controls'));
    
    // init player API
    const element = $modal.find('.youtube-player-placeholder')[0]; // Or an HTMLElement reference
    let videoPlayer = new YouTubePlayerPlus(element, {});

    $(poster).on('click', function (e) {
      $button.trigger('click');
    });

    // Open player
    $button.on('click', function (e) {
      e.stopPropagation();
      e.preventDefault();
      $('html').addClass('player-active');

      $modal.addClass('player-active');
      $modal.attr('aria-hidden', false);
      $modal.focus();

      videoPlayer.load($modal.find('.youtube-player-placeholder').data('videoid'), true);
    });

    // Close player
    $modal.find('.close-player').on('click', function (e) {
      e.preventDefault();

      // Stop the video
      videoPlayer.stop();

      $modal.removeClass('player-active');
      $('section.video-player .video-modal').attr('aria-hidden', true);
      $('html').removeClass('player-active');
    });

    // Esc to close player
    $(document).on('keyup', function(e) {
      if (e.key === "Escape") {
        $modal.find('.close-player').trigger('click');
      }
    });
  });
});
