import $ from 'jquery';

import './utils/common.js';
import './utils/video-player.js';
import './components/_homepage.js';
import './components/_vertical-navbar.js';
import './components/_scroll.js';
import './components/_history-carousel.js';
import './components/_report.js';
import './components/_events.js';
import './components/_map.js';
import './components/_hubs.js';
import './components/_innovation-cards.js';

window.jQuery = $;
