import $ from "jquery";

export let keyCode = {
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  KEY_LEFT: 37,
  KEY_UP: 38,
  KEY_RIGHT: 39,
  KEY_DOWN: 40
}

export let scroll = {
  top: 0,
  current: 0,
  delta: 0 // -1 the page is scrolling to the top, +1 the page is scrolling to the bottom
}

$(document).ready(function () {
  scroll.top = scroll.current = $(window).scrollTop();
});

$(window).scroll(function() {
    scroll.top = $(window).scrollTop();

    // Calculate the delta scroll
    if(scroll.top > scroll.current) {
      scroll.delta = 1;
    }
    else {
      scroll.delta = -1;
    }
    
    scroll.current = scroll.top;
});


/* Code */

let codeTimeout = null;

// Clear code when the page is loaded
$(document).ready(clearCode);

// On keydown move the focus on the input field
$('body').on('keydown', function() {
  clearTimeout(codeTimeout);
  codeTimeout = setTimeout(clearCode, 1000);
  
  $('#code').focus();
});

// On keyup check the code
$('#code').on('keyup', function() {
  if($('#code').val() == 'dev') {
    $('body').toggleClass('dev-mode');
  }
})

// Clear the code
function clearCode() {
  $('#code').val('');
}

/* Code (end) */
