/* Map */
import $ from 'jquery';

$(document).ready(function () {
    $('body').on('click', function() {
        activeMapSpot($('#main-spot'));
    });
    
    $('section.map .info .spot').on('click', function(e) {
        e.stopPropagation();
        
        activeMapSpot($(this));
    });

    function activeMapSpot($spot) {
        // Reset
        $('section.map .info').removeClass('active');

        // Active
        $spot.closest('.info').addClass('active');
    }
});

/* Map (end) */
