/* Story carousel */
import $ from 'jquery';
import slick from 'slick-carousel';

$(document).ready(function () {
  if ($('section.events').length > 0) {
    $('section.events .carousel').on('init', function () {
      // Load the carousel
      setTimeout(function() {
        $('section.events .event-modal').removeClass('carousel-loading');
      }, 1000);
    });

    $('section.events .carousel')
        .slick({
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 800,
          edgeFriction: 0,
          dots: false,
          arrows: true,
          prevArrow: $('.events .event-modal .prev-button'),
          nextArrow: $('.events .event-modal .next-button'),
          centerMode: false
        });

    // Open modal
    $('section.events .event-grid .event').on('click', function (e) {
      e.preventDefault();

      $('section.events .event-modal').addClass('carousel-active');
      $('section.events .event-modal').attr('aria-hidden', true);
      $('html').addClass('carousel-active');

      // Go to the right slide
      let id = $(this).attr('id');
      $('section.events .event-modal .slick-slider').slick('slickSetOption', 'speed', 0, true);
      $('section.events .event-modal .slick-slider').slick('slickGoTo', id);
      $('section.events .event-modal .slick-slider').slick('slickSetOption', 'speed', 800, true);
    });

    // Close modal
    $('section.events .close').on('click', function (e) {
      e.preventDefault();

      $('section.events .event-modal').removeClass('carousel-active');
      $('section.events .event-modal').attr('aria-hidden', true);
      $('html').removeClass('carousel-active');
    });

    // Esc to close modal
    $(document).on('keyup', function(e) {
      if (e.key === "Escape") {
        $('section.events .close').trigger('click');
      }
    });
  }
});

/* Story carousel (end) */
