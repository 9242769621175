/* Scroll */
import $ from 'jquery';

$(document).ready(function () {
    if($('section.scroll').length > 0) {

        $('section.scroll .scroll-wrapper').on('click', function() {
            let header = $('header.header').outerHeight();
            let anchor = $('#scroll-anchor').position().top;
    
            let scroll = Math.floor(anchor - header);
            
            $('html, body').animate({
                scrollTop: scroll
            }, 500, 'swing');
        })
    }
});

/* Scroll (end) */
