/* Vertical navbar */
import $ from 'jquery';
import { scroll } from '../utils/common.js';

let anchors = [];

$(document).ready(function () {
  createAnchorList();
  activeAnchor();
});

$(window).scroll(function () {
  activeAnchor();
});

function createAnchorList() {
  if ($('section.vertical-navbar').length > 0) {
    // Create the list of anchors
    $($('section.vertical-navbar nav ul li').get().reverse()).each(function () {
      let id = $(this).find('a').attr('href');
      if ($(id).length > 0) {
        let scroll = Math.ceil($(id).offset().top);
        anchors[id] = scroll;
      }
    });
  }
}

// Go to an anchor
$('section.vertical-navbar nav ul li a').on('click', function (e) {
  e.preventDefault();

  $('section.vertical-navbar nav ul li a').removeClass('active');
  $(this).addClass('active');

  let id = $(this).attr('href');

  if (anchors[id] !== undefined) {
    let scroll = anchors[id];

    $('html, body').animate(
      {
        scrollTop: scroll
      },
      1000,
      undefined,
      activeAnchor
    );
  }

  return false;
});

function activeAnchor() {
  if ($('section.vertical-navbar').length > 0) {
    $('section.vertical-navbar nav ul li a').removeClass('active');

    // Check where is the scroll
    for (let id in anchors) {
      if (scroll.top >= anchors[id]) {
        $('section.vertical-navbar nav ul li a[href="' + id + '"]').addClass('active');
        break;
      }
    }
  }
}

/* Vertical navbar (end) */
