/* Hubs */
import $ from 'jquery';

$(document).ready(function () {
    $('body').on('click', function() {
        resetHubSpot();
    });
    
    $('section.hubs .hub .spot').on('click', function(e) {
        e.stopPropagation();
        
        resetHubSpot();
        activeHubSpot($(this));
    });
    
    function activeHubSpot($spot) {
        let id = $spot.closest('.hub').attr('aria-controls');

        $spot.closest('.hub').addClass('active');
        $('section.hubs .hub-modal#' + id).addClass('active');
    }

    function resetHubSpot() {
        $('section.hubs .hub').removeClass('active');
        $('section.hubs .hub-modal').removeClass('active');
    }
});

/* Hubs (end) */
