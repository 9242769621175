/* History carousel */
import $ from 'jquery';

let position = [];

$(document).ready(function () {
  if ($('section.history-carousel').length > 0) {
    // Get the position
    
    $('.history-carousel .carousel .slide').each(function(index, slide) {
      position.push(Math.floor($(slide).position().left));
    });

    // Navigation
    $('section.history-carousel header nav ul li p').on('click', function (e) {
      e.preventDefault();

      // Go to the right slide
      let id = $(this).attr('id');

      $('section.history-carousel .carousel-scroll').animate({
        scrollLeft: position[id]
      }, 500, 'swing');
    });
  }
});

// Active the anchor
$('section.history-carousel .carousel-scroll').scroll(function() {
  $('.history-carousel header nav ul li p.active').removeClass('active');

  let scroll = $('section.history-carousel .carousel-scroll').scrollLeft();

  for(let i=position.length-1; i>=0; i--) {
    if(scroll >= position[i]) {
      $('section.history-carousel header nav #' + i).addClass('active');
      return;
    }
  }
});

/* History carousel (end) */
